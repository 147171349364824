.social-bar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: fit-content;
  width: 100%;
  margin: 2rem 0;
  background-color: #2b2c3e;
  box-shadow: inset 0 0 10px #222222;
  border-radius: 50px;
  /* border: 2px solid #56d6ff; */
}

.social-bar * {
  transition: 0.1s;
}

.social-bar a {
  padding: 0.8rem;
  border-radius: 50%;
}

.social-bar a:hover {
  background-color: #111114;
  transform: scale(1.2);
}

.social-bar a:hover:first-child {
  background-color: #7289da;
}

.social-bar a:hover:nth-child(3) {
  background-color: #fff;
}

.social-bar a:hover:first-child svg {
  fill: #fff;
}

.social-bar a:hover:nth-child(2) svg {
  fill: #fff;
}

.social-bar a:hover:nth-child(3) svg {
  fill: #f00;
}
