@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #151834;
  box-shadow: inset 0 0 5px #000;
}

::-webkit-scrollbar-thumb {
  background: #56d6ff;
}

.profileImage {
  background-color: #151834;
  width: 75px;
  height: 75px;
  object-fit: contain;
}

.profileRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.bg-color {
  background-color: #2b2c3e;
}

.bg-color-dark {
  background-color: #151834;
}

.problem-table {
  background-color: rgba(255, 255, 255, 0.1);
  border: #fff solid 2px;
  border-radius: 25px;
}

.entire-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
}

.table-head {
  background-color: #151834;
  border-radius: 15px;
}

.table-head tr {
  display: grid;
  grid-template-columns: 1fr 4fr 4fr 1fr 1fr;
  padding: 15px 10px;
}

.table-body {
  /* text-align: start; */
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.table-row {
  display: grid;
  grid-template-columns: 1fr 4fr 4fr 1fr 1fr;
  border-radius: 15px;
  padding: 15px 10px;
  /* background-color: aqua; */
  width: 100%;
}

.bg-green {
  /* background: linear-gradient(to left, #4ade80 12.4%, #2b2c3e 12.5%); */
  color: #4ade80;
}

.search-bar {
  display: flex;
  background-color: #151834;
  height: 40px;
  border-radius: 25px;
  padding: 0 10px;
  box-shadow: 2px 2px 5px inset #1b1b1b;
}

.search-bar input {
  outline: none;
  background-color: transparent;
}

.top-row {
  /* display: flex; */
  /* flex-direction: column-reverse; */
  /* grid-template-columns: 8fr 2fr 1fr; */
  /* padding: 0; */
  /* margin-bottom: 20px; */
}


.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #333333; */
  transition: opacity 0.75s, visibility 0.75s;
}

.loader--hidden {
  opacity: 0;
  visibility: hidden;
}

.loader::after {
  content: "";
  width: 75px;
  height: 75px;
  border: 15px solid #dddddd;
  border-top-color: #009578;
  border-radius: 50%;
  animation: loading 0.75s ease infinite;
}

@keyframes loading {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
