.footer {
  background-color: #151834;
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 10px 50px;
}

.bottom {
  font-size: 32px;
  position: relative;
  padding: 25px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.bottom span {
  transform: translateY(10px);
}

.bottom img {
  width: 200px;
  object-fit: contain;
}

.bottom::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #151834;
  transform: skewX(-5deg);
  top: 0%;
  left: 0%;
  z-index: -1;
}

.bottom::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: skewY(1deg);
  background-color: #56d6ff;
  top: 0%;
  left: 0%;
  transition: 0.2s;
  z-index: -1;
}
